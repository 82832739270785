<template>
  <div>
    <!-- Paginations -->
    <div class="d-flex justify-start">
      <p class="mt-2 mr-2 section-title mb-1">Wide</p>
      <v-btn icon color="caribbeanDark" @click="copyToClipboard('Wide')">
        <v-icon>mdi-code-tags</v-icon>
      </v-btn>
    </div>
    <v-container class="container-style mb-4">
      <!-- Wide -->
      <Pagination
        type="wide"
        v-model="pageOptionsWide"
        @update-list="updateList()"
      />
      <!-- Wide -->
    </v-container>

    <div class="d-flex justify-start">
      <p class="mt-2 mr-2 section-title mb-1">Fit</p>
      <v-btn icon color="caribbeanDark" @click="copyToClipboard('Fit')">
        <v-icon>mdi-code-tags</v-icon>
      </v-btn>
    </div>
    <v-container class="container-style mb-4">
      <!-- Fit -->
      <Pagination
        type="fit"
        v-model="pageOptionsFit"
        @update-list="updateList()"
      />
      <!-- Fit -->
    </v-container>

    <div class="d-flex justify-start">
      <p class="mt-2 mr-2 section-title mb-1">Narrow</p>
      <v-btn icon color="caribbeanDark" @click="copyToClipboard('Narrow')">
        <v-icon>mdi-code-tags</v-icon>
      </v-btn>
    </div>
    <v-container class="container-style mb-4">
      <!-- Narrow -->
      <Pagination
        type="narrow"
        v-model="pageOptionsNarrow"
        @update-list="updateList()"
      />
      <!-- Narrow -->
    </v-container>
  </div>
</template>

<script>
import Pagination from '@/components/shared/customComponents/paginations/pagination.vue';

export default {
  name: 'PaginationWiki',
  components: {
    Pagination,
  },

  data: () => ({
    pageOptionsWide: {
      pageSizes: [5, 10, 20, 50],
      page: 1,
      length: 10,
      pageSize: 20,
      total: 0,
    },
    pageOptionsFit: {
      pageSizes: [5, 10, 20, 50],
      page: 1,
      length: 10,
      pageSize: 20,
      total: 0,
    },
    pageOptionsNarrow: {
      pageSizes: [5, 10, 20, 50],
      page: 1,
      length: 10,
      pageSize: 20,
      total: 0,
    },
  }),

  methods: {
    updateList() {
      console.log('List updated');
    },

    selectedComponentHandler(name) {
      let selectedComponent = null;

      switch (name) {
        case 'Wide':
          selectedComponent = `<Pagination
              type="wide"
              v-model="pageOptions"
              @update-list="updateList()"
            />`;
          break;
        case 'Fit':
          selectedComponent = `<Pagination
              type="Fit"
              v-model="pageOptions"
              @update-list="updateList()"
            />`;
          break;
        case 'Narrow':
          selectedComponent = `<Pagination
              type="Narrow"
              v-model="pageOptions"
              @update-list="updateList()"
            />`;
          break;
      }
      return selectedComponent;
    },

    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = this.selectedComponentHandler(name);
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },
  },
};
</script>

<style scoped lang="scss">
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}
</style>
